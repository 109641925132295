import {DrugAuditListEntry, DrugAuditPagedResponse, DrugBatchBasic} from "../../../api/mm";
import moment from "moment";
import {toArray} from "../../../utils/sortingUtils";
import {NotificationItem, SubSystem} from "../actions/NotificationsHubActionTypes";
import {formatUnixToDDMMYYYY, getNumberOfDaysBetweenDates} from "../../../utils/momentUtils";
import React from "react";
import Icon, {IconType} from "../../../components/Icon/Icon";
import {getUiFriendlyText} from "../../../utils/textUtils";
import {Bold} from "../../../components/Pages/Dashboard/Components/LiveIncidentTracker/Components/BoldText";

export function convertDrugAuditResponseToNotificationItem(
    payload: DrugAuditPagedResponse
): NotificationItem[] {
    return payload.results.map((result) => {
        return {
            id: result.auditId,
            subSystem: SubSystem.MM,
            icon: <Icon rootElement={"Span"} icon={IconType.DrugNotification} size={"Large"} />,
            title: getMMDrugAuditNotificationTitle(result),
            actionText: getMMDrugAuditNotificationActionText(result),
            link: getLinkForMMDrugAuditNotification(result)
        };
    });
}

/** Converts the raw data from MM and returns drugs that are expiring in the month and in the week. */
export function convertActiveDrugBatchesToNotificationItem(payload: DrugBatchBasic[]) {
    const drugs: DrugBatchBasic[] = getExpiringDrugsWithinTheMonth(toArray(payload));
    const notificationList: NotificationItem[] = [];

    for (const drug of drugs) {
        notificationList.push({
            subSystem: SubSystem.MM,
            icon: getMMNotificationItemIcon(drug),
            id: drug.id,
            title: getMMDrugBatchExpiryNotificationTitle(drug),
            actionText: getMMDrugBatchNotificationActionText(drug),
            link: getLinkForMMDrugExpiryNotification(drug)
        });
    }

    return notificationList;
}

function getExpiringDrugsWithinTheMonth(drugBatches: DrugBatchBasic[]) {
    //Order by expiring first.
    const drugs: DrugBatchBasic[] = sortDrugsByExpiryDateAscending(drugBatches);
    const now = moment().startOf("day");
    const oneMonthInFuture = moment.unix(now.unix()).add(1, "month");

    const filteredDrugs: DrugBatchBasic[] = [];

    for (const drug of drugs) {
        if (drug.expiryDate > now.unix() && drug.expiryDate < oneMonthInFuture.unix()) {
            filteredDrugs.push(drug);
        }
    }

    return filteredDrugs;
}

function getMMDrugBatchNotificationActionText(drug: DrugBatchBasic): JSX.Element {
    const now = moment(new Date().getTime());
    const daysUntilExpiry = getNumberOfDaysBetweenDates(now, moment.unix(drug.expiryDate));
    return (
        <p className="notification-item-message">
            <Bold>{drug.name}</Bold> - <Bold>{drug.batchNumber}</Bold> - is expiring in{" "}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <Bold>{`${daysUntilExpiry} days`}</Bold>. Click the "View" button to take action!
        </p>
    );
}

function getMMDrugAuditNotificationActionText(drug: DrugAuditListEntry): JSX.Element {
    return (
        <p className="notification-item-message">
            A <Bold>{getUiFriendlyText(drug.type)}</Bold> has been created by{" "}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <Bold>{drug.originalAuthor.staffName}</Bold> on the{" "}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <Bold>{formatUnixToDDMMYYYY(drug.dateCreated)}</Bold>
            {getModifiedAuditText(drug)} Click
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {/* eslint-disable-next-line react/no-unescaped-entities */} the "View" button to take
            action!
        </p>
    );
}

const getModifiedAuditText = (drug: DrugAuditListEntry) => {
    const hasAuditBeenModified = checkForModifiedAudit(drug);

    if (hasAuditBeenModified) return <span>.</span>;

    return (
        <span>
            {" "}
            and was modified by <Bold>{drug.modifiedAuthor.staffName}</Bold> on the{" "}
            <Bold>{formatUnixToDDMMYYYY(drug.dateModified)}</Bold>.
        </span>
    );
};

function checkForModifiedAudit(drug: DrugAuditListEntry) {
    const flooredCreatedDateSecond = roundTimeUpOrDown(drug.dateCreated);
    const flooredModifiedDateSecond = roundTimeUpOrDown(drug.dateModified);

    return flooredCreatedDateSecond === flooredModifiedDateSecond;
}

function roundTimeUpOrDown(timeStamp: number) {
    const momentTimeStamp = moment.unix(timeStamp);

    if (momentTimeStamp.get("second") <= 30) {
        return momentTimeStamp.startOf("minute").unix();
    }

    return momentTimeStamp.endOf("minute").add(1, "second").unix();
}

function getMMNotificationItemIcon(drug: DrugBatchBasic): JSX.Element {
    const now = moment().startOf("day");
    const daysUntilExpiry = getNumberOfDaysBetweenDates(now, moment.unix(drug.expiryDate));
    return (
        <React.Fragment>
            <Icon
                rootElement={"Span"}
                icon={IconType.DrugNotification}
                size={"Large"}
                className={getMMIconColour(daysUntilExpiry)}
            />
        </React.Fragment>
    );
}

function getMMIconColour(daysUntilExpiry: number) {
    return daysUntilExpiry > 7 ? "orange-icon" : "red-icon";
}

function getMMDrugAuditNotificationTitle(drug: DrugAuditListEntry): string {
    const prefix = "Medicine Management - Drug Audit -";
    const formattedDate = formatUnixToDDMMYYYY(drug.dateCreated);
    return `${prefix} ${formattedDate}`;
}

function getMMDrugBatchExpiryNotificationTitle(drug: DrugBatchBasic): string {
    const prefix = "Medicine Management - Drug Expiry -";
    const formattedDate = formatUnixToDDMMYYYY(drug.expiryDate);
    return `${prefix} ${formattedDate}`;
}

function getLinkForMMDrugExpiryNotification(drug: DrugBatchBasic): string {
    return `${process.env.REACT_APP_MM}stock-management/all?DBSO=ExpiryDateAscending&DBT=None&DRG=${drug.name}&BAT=${drug.batchNumber}`;
}

function getLinkForMMDrugAuditNotification(drug: DrugAuditListEntry): string {
    const start = moment.unix(drug.dateCreated).startOf("month").unix();
    const end = moment.unix(drug.dateCreated).endOf("month").unix();
    return `${process.env.REACT_APP_MM}audits/edit/${drug.auditId}?&CDS=${start}&CDE=${end}&pageNum=0&type=None`;
}

function sortDrugsByExpiryDateAscending(drugBatches: DrugBatchBasic[]): DrugBatchBasic[] {
    return toArray(drugBatches).sort((a: DrugBatchBasic, b: DrugBatchBasic) => {
        if (a.expiryDate > b.expiryDate) {
            return 1;
        }
        if (a.expiryDate < b.expiryDate) {
            return -1;
        }
        return 0;
    });
}
