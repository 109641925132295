import React from "react";
import {CalendarTypeEnum, StaffWageReportEntry} from "../../../../api/grs";
import Icon, {IconType} from "../../../Icon/Icon";
import moment from "moment";
import Tooltip from "../../../Tooltip/Tooltip";

const WageCalculationTableHeader = (props: StaffWageReportEntry) => {
    const viewCalendar = () => {
        const grsPath = process.env.REACT_APP_GRS;
        const calendarName = encodeURI(encodeURI(props.calendarName));
        const calendarId = props.calendarId;
        const weekMonthQuery = getMonthOrWeekQueryString(props);
        const startOfMonth = moment.unix(props.startDate).startOf("month").unix();

        const url = `${grsPath}calendar/${calendarName}?id=${calendarId}&${weekMonthQuery}=${startOfMonth}`;

        window.open(url, "_self");
    };

    const getMonthOrWeekQueryString = (report: StaffWageReportEntry) => {
        switch (report.calendarType) {
            case CalendarTypeEnum.Event:
                return "month";
            case CalendarTypeEnum.Frontline:
                return "week";
        }
    };
    return (
        <thead>
            <tr className="mc-table-header">
                <th align="center" className="mc-table-header-item w-100 p-3">
                    {props.calendarName}
                </th>
                <th className="mc-table-header-item w-100 p-3" align="right">
                    <div className="icon-tooltip-wrapper-lg float-right">
                        <Tooltip
                            theme={"light"}
                            tooltipText={"View Calendar"}
                            size={"md"}
                            place={"left"}
                        >
                            <Icon
                                rootElement={"Div"}
                                rootElementClassName="d-flex justify-content-end"
                                icon={IconType.Calendar}
                                className="icon-light"
                                size={"Large"}
                                onClick={viewCalendar}
                            />
                        </Tooltip>
                    </div>
                </th>
            </tr>
        </thead>
    );
};

export default WageCalculationTableHeader;
