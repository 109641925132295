import React, {useEffect} from "react";
import SectionContainer from "../../../../Containers/SectionContainer";
import Icon, {IconType} from "../../../../Icon/Icon";
import {useDispatch, useSelector} from "react-redux";
import StatsItem from "./Components/StatsItem";
import {
    getOverallPrfStats,
    nullifyOverallComplianceStore
} from "../../../../../store/statistics/prf/overallCompliance/actions/OverallComplianceActions";
import {RootStore} from "../../../../../store/Store";
import {
    fetchViewCountForCategoryStats,
    nullifyViewCountStore
} from "../../../../../store/statistics/ppb/documentViewStats/actions/DocumentViewStatsActions";
import {
    getActiveDrugBatchStats,
    nullifyDrugBatchListStore
} from "../../../../../store/statistics/mm/drugBatchList/actions/DrugBatchListStatsActions";
import Tooltip from "../../../../Tooltip/Tooltip";
import AbsenceStatsContainer from "./Absence/AbsenceStatsContainer";
import {fetchAllActiveDrugBatches} from "../../../../../store/mm/drugBatchList/actions/DrugBatchListActions";
import {useUserData} from "../../../../Hooks/useUserData";
import {AccessDetailType, doesUserHaveAdminAccess} from "../../../../../utils/userDataUtils";

const ComplianceStatsContainer = () => {
    const dispatch = useDispatch();
    const overallComplianceStore = useSelector((state: RootStore) => state.overallCompliance);
    const documentViewStatsStore = useSelector((state: RootStore) => state.documentViewStats);
    const drugBatchListStatsStore = useSelector((state: RootStore) => state.drugBatchListStats);
    const drugBatchListRawStore = useSelector((state: RootStore) => state.drugBatchList);
    const user = useUserData();

    useEffect(() => {
        return () => {
            dispatch(nullifyOverallComplianceStore());
            dispatch(nullifyViewCountStore());
            dispatch(nullifyDrugBatchListStore());
        };
    }, []);

    useEffect(() => {
        if (!user.username.length) return;
        refreshStats();
    }, [user]);

    useEffect(() => {
        if (!user.username.length) return;
        const {data} = drugBatchListRawStore;
        if (!data) return;
        if (!doesUserHaveAdminAccess(AccessDetailType.mm, user)) return;
        dispatch(getActiveDrugBatchStats(data));
    }, [drugBatchListRawStore, user]);

    const refreshStats = (fetchDrugBatches = false) => {
        if (doesUserHaveAdminAccess(AccessDetailType.prf, user)) {
            dispatch(getOverallPrfStats());
        }
        if (doesUserHaveAdminAccess(AccessDetailType.ppb, user)) {
            dispatch(fetchViewCountForCategoryStats());
        }

        //We don't want to call to the service to get this when the store already has the data.
        if (!fetchDrugBatches) return;
        if (doesUserHaveAdminAccess(AccessDetailType.mm, user)) {
            dispatch(fetchAllActiveDrugBatches());
        }
    };
    return (
        <React.Fragment>
            <SectionContainer>
                <div className="row pt-3 ml-0 mr-0">
                    <div className="live-tracker-header-item mr-auto">
                        <h6>
                            <b className="container-info-title header-font">
                                Key Performance Indicators
                            </b>
                        </h6>
                    </div>
                    <div className="live-tracker-header-item">
                        <Tooltip
                            wrapperClassName="cursor-pointer"
                            tooltipText={"Refresh Compliance Statistics"}
                            size={"md"}
                            place={"left"}
                            theme={"dark"}
                        >
                            <Icon
                                className="mr-3 tracker-icon-button"
                                rootElement={"Span"}
                                icon={IconType.Refresh_Square}
                                size={"Medium"}
                                onClick={() => refreshStats(true)}
                            />
                        </Tooltip>
                    </div>
                </div>
                <div className="stats-grid">
                    {drugBatchListStatsStore.data &&
                        doesUserHaveAdminAccess(AccessDetailType.mm, user) && (
                            <StatsItem {...drugBatchListStatsStore.data} />
                        )}
                    {overallComplianceStore.data &&
                        doesUserHaveAdminAccess(AccessDetailType.prf, user) && (
                            <StatsItem {...overallComplianceStore.data} />
                        )}
                    {documentViewStatsStore.data &&
                        doesUserHaveAdminAccess(AccessDetailType.prf, user) && (
                            <StatsItem {...documentViewStatsStore.data} />
                        )}
                    {doesUserHaveAdminAccess(AccessDetailType.staffmanagement, user) && (
                        <AbsenceStatsContainer />
                    )}
                </div>
            </SectionContainer>
        </React.Fragment>
    );
};

export default ComplianceStatsContainer;
